import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
    <h1 className="text-3xl font-bold text-gray-800 mb-4">
      Website Under Maintenance
    </h1>
    <p className="text-lg text-gray-600 mb-6 text-center">
      We're currently updating the website and will be back soon. Expected
      to be live by 10th November 2024.
    </p>

    <p className="text-lg text-gray-600 mb-4">
      In the meantime, follow us on:
    </p>

    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <SocialMediaLink
        platform="Facebook"
        url="https://facebook.com/profile.php?id=61561635335881"
        color="bg-blue-600"
      />
      <SocialMediaLink
        platform="Instagram"
        url="https://instagram.com/joshskitenge/"
        color="bg-pink-500"
      />
      <SocialMediaLink
        platform="TikTok"
        url="https://tiktok.com/@joshskitenge?_t=8qosijhywGc&_r=1"
        color="bg-black"
      />
      <SocialMediaLink
        platform="X"
        url="https://x.com/Joshskitenge"
        color="bg-blue-400"
      />
    </div>
  </div>
);
}

const SocialMediaLink = ({ platform, url, color }) => {
return (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={`${color} hover:bg-gray-800 text-white font-bold py-4 px-6 rounded-lg flex justify-center items-center transition-colors duration-300`}
  >
    {platform}
  </a>
  );
}

export default App;
